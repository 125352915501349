import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import { Fade, Zoom } from "react-awesome-reveal"

export default function TarotMeanings(props) {
  const card = props.cards[`${props.random}`]

  return (
    <Card className="w-100" style={{ maxWidth: `50rem` }}>
      <Fade delay={2000} duration={3000} triggerOnce={true}>
        <Card.Header
          className="m-3 p-3 text-center"
          style={{ textShadow: `0.2rem 0.2rem #412456` }}
        >
          <h3>{card.card_name}</h3>
        </Card.Header>
      </Fade>
      <Fade delay={4000} duration={5000} triggerOnce={true}>
        <Card.Body className="w-100">
          <Card.Title className="TCardImg">
            <Zoom delay={5000} duration={8000} triggerOnce={true}>
              <GatsbyImage
                image={card.image.asset.gatsbyImageData}
                alt={card.card_name}
              />
            </Zoom>
          </Card.Title>
          <Card.Text
            className="w-100 mt-3 p-3 text-center"
            style={{
              color: `var(--gold)`,
              fontSize: `1.6rem`,
              fontFamily: `poppins, sans-serif`,
              fontStyle: `normal`,
              fontWeight: `500`,
            }}
          >
            {card.trad_meaning}
          </Card.Text>
          <Card.Text
            className="w-100 mt-1 p-3 text-center"
            style={{
              color: `var(--gold)`,
              fontSize: `1.6rem`,
              fontFamily: `poppins, sans-serif`,
              fontStyle: `normal`,
              fontWeight: `500`,
            }}
          >
            {card.interp_meaning}
          </Card.Text>
          <Card.Text
            className="w=100 mt-1 p-3 text-center"
            style={{
              color: `var(--gold)`,
              fontSize: `1.6rem`,
              fontFamily: `poppins, sans-serif`,
              fontStyle: `normal`,
              fontWeight: `500`,
            }}
          >
            {card.extra_meaning}
          </Card.Text>
        </Card.Body>
      </Fade>
      <Fade delay={3000} duration={3500} triggerOnce={true}>
        <Card.Footer className="d-flex-column">
          <div className="w-100 d-flex mb-3">
            <div className="w-50 text-center">
              <span
                style={{
                  fontSize: `1.6rem`,
                  color: `var(--gold)`,
                  fontFamily: `poppins, sans-serif`,
                  fontWeight: `600`,
                  fontStyle: `bold`,
                }}
              >
                <h5
                  style={{
                    color: `var(--gold)`,
                    textShadow: `0.25rem 0.25rem #412456`,
                  }}
                >
                  Arcana:
                </h5>
                {card.arcana}
              </span>
            </div>
            <div className="w-50 text-center">
              <span
                style={{
                  fontSize: `1.6rem`,
                  color: `var(--gold)`,
                  fontFamily: `poppins, sans-serif`,
                  fontWeight: `600`,
                  fontStyle: `bold`,
                }}
              >
                <h5
                  style={{
                    color: `var(--gold)`,
                    textShadow: `0.25rem 0.25rem #412456`,
                  }}
                >
                  Suit:
                </h5>{" "}
                {card.card_suit}
              </span>
            </div>
          </div>
          <div className="w-100">
            <Link to="/readings" className="w-100">
              <Button className="w-100" type="button">
                See How It Impacts A Spread
              </Button>
            </Link>
          </div>
        </Card.Footer>
      </Fade>
    </Card>
  )
}
