import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import TarotMeanings from "../components/tarotmeanings"
import TarotIntroduction from "../components/tarotintroduction"

const StyledContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
`

export default function TarotPage({ data }) {
  const [intro, setIntro] = useState(true)
  const cards = data.cards.nodes
  const random = Math.floor(Math.random() * 7)

  function handleClick(e) {
    e.preventDefault();
    setIntro(!intro);
  }

  return (
    <>
      <SEO title="Tarot Cards" />
      <StyledContainer>
        {intro
      ?  <TarotIntroduction handleClick={(e) => handleClick(e)} />
      :  <TarotMeanings cards={cards} random={random} /> 
        } 
        </StyledContainer>
    </>
  )
}

export const query = graphql`
  query tarotQuery {
    cards: allSanityTarot {
      nodes {
        id
        card_name
        arcana
        card_suit
        trad_meaning
        interp_meaning
        extra_meaning
        image {
          asset {
           gatsbyImageData(width: 600, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
