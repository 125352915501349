import React from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"


export default function TarotIntroduction(props) {
  return (

    <Card className="w-100" style={{maxWidth: `50rem`}}>
      <Card.Header className="m-3 p-3 text-center" style={{textShadow: `0.2rem 0.2rem #412456`}}>
        <h3>  Daily Tarot Meditation </h3>
      </Card.Header>
      <Card.Body className="w-100 m-3">
        <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>Getting to know the tarot isn't as difficult or time consuming a task as you might first think, especially if you take it at your own pace.</Card.Text>
        <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>
         It can be for a day, a week, or even a month if you choose.</Card.Text>
         <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>Once you've revealed a card by clicking the button below, Take note of its meaning.</Card.Text>
         <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>Study the symbols, icons, and other notable depictions in the graphics.</Card.Text>
         <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>Jot down your first thoughts when you look at the card, and include your own description of what you see.</Card.Text>
         <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>Then, for the time period you've determined, keep track of your day to day life.</Card.Text>
         <Card.Text className="w-75 mx-auto" style={{fontSize: `1.4rem`, color: `var(--gold)`}}>At the end of the allotted time, compare the two sets of notes to see how much of that particular card has manifested itself into your world.</Card.Text>
      </Card.Body>
      <Card.Footer className="m-0 p-0">
        <Button className="w-100" type="submit" onClick={(e) => props.handleClick(e)} >Reveal A Card</Button>
      </Card.Footer>
    </Card>
  )
}